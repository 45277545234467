import screenshot_dark from './assets/interface_dark.png';
import screenshot_light from './assets/interface_light.png';
import logo from './assets/logo.png';
import './css/App.css';
import Usage from "./Usage";

const App = () => {
    return (
        <div className="App">
            <h1><img className="logo" src={logo} alt="DNSResolver logo"/> DNS Resolver</h1>
            <p>
                This Tool was developed with the Goal to make it easier to get as much information about a specific
                domain as possible with one search.
            </p>

            <h2>Download</h2>
            <p>
                Windows installer: <a href="https://dnsresolver.ch/installer/dnsresolver.exe"
                                      rel="noopener noreferrer">Download</a>
            </p>

            <Usage/>

            <h2>Screenshots:</h2>
            <div className="screenshots">
                <img src={screenshot_light} alt="screenshot of the DNSResolver in the light version"/>
                <img src={screenshot_dark} alt="screenshot of the DNSResolver in the dark version"/>
            </div>
        </div>
    );
}

export default App;
