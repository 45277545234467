const Usage = () => {
    return (<>
            <h2>Usage</h2>
            <p className="left-para">
                The main use of this application is to get DNS and WHOIS Data for a specific domain. But this is not the
                only thing
                this tool can do.
                For example after searching for a domain you can click on the IP address below "Hostname" to get more
                information about the IP-Address in the A-Record. It is also possible to search directly for an
                IP-Address and get the same information.
            </p>
            <h3>More functions</h3>
            <ul className="left-para">
                <li>If you are searching for a subdomain you can also click on the "Hostname" to instantly search for
                    the domain itself.
                </li>
                <li>To use a custom DNS-Server you can click on the arrow on the top left to open the advanced
                    settings.
                </li>
                <li>
                    If the domain has a TXT Record which matches a SPF-Record, you get a automatic
                    generated link to <a href="https://www.spf-record.com/analyzer"
                                         rel="noopener noreferrer">https://www.spf-record.com/analyzer</a>. This
                    website helps to find problems with the record and help you generate a valid one.
                </li>
                <li>
                    the searchbar will filter out different things like the http/https protocol. To search for
                    google.com you could
                    also just search for https://google.com/test/123/whatever. The format hxxps://google[.]com. also
                    works without problems.
                    This one is often used if the domain is in an abuse case.
                </li>
                <li>
                    The Whois requests are possible for all TLDs and most of the ccTLDs.
                    With this you do not have to look for a webpage of the registrar to get the WHOIS information.
                </li>
                <li>Currently the following Types can be chosen, when searching for a domain: ANY, A, AAAA, CNAME, NS, MX, SRV, TXT, SOA</li>
            </ul>
        </>);
}

export default Usage;